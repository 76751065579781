<template>
    <div class="pagination-container">
        <div class="pagination-container-links">
            <button @click="onChangePage(links.first)" :disabled="!links.first" type="button">
                <ico name="icoSkipPrevious" color="#79919C"/>
            </button>
            <button @click="onChangePage(links.prev)" :disabled="!links.prev" type="button">
                <ico name="icoNavigateBefore" color="#79919C"/>
            </button>
            <div class="pagination-container-links-text">
                {{getTextString}}
            </div>
            <button @click="onChangePage(links.next)" :disabled="!links.next" type="button">
                <ico name="icoNavigateNext" color="#79919C"/>
            </button>
            <button @click="onChangePage(links.last)" :disabled="!links.last" type="button">
                <ico name="icoSkipNext" color="#79919C"/>
            </button>
        </div>

        <div class="pagination-container-size-selector">
            <div class="pagination-container-size-selector-text">Строк на странице:</div>
            <EntitySelect
                    :value="pagination"
                    :entities="paginationTypes"
                    @onLoad="getPaginationTypes"
                    @onChange="onChangePaginationSize"
                    :is-small="true"
                    style="max-width: none;"
            />
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex"
    import EntitySelect from "./EntitySelect"
    import {isEmpty} from "lodash"

    export default {
        components: {
            EntitySelect,
        },
        data() {
            return {

            }
        },
        props: ['page', 'links'],
        name: "Pagination",
        computed: {
            ...mapGetters('purchasesTableSettings', ['pagination']),
            ...mapGetters('enums', ['paginationTypes']),
            getTextString() {
                const startDigit = isEmpty(this.page) ? 0 : Math.min(this.page.number * this.page.size + 1, this.page.totalElements)
                const endDigit = !startDigit ? 0 : Math.min(startDigit + this.page.size - 1, this.page.totalElements)

                return `${startDigit} - ${endDigit} из ${this.page.totalElements ?? 0}`
            }
        },
        methods: {
            ...mapActions('purchasesTableSettings', ['updatePaginationSize']),
            ...mapActions('enums', ['getPaginationTypes']),
            async onChangePaginationSize(value) {
                if (value) {
                    try {
                        await this.updatePaginationSize(value)
                        this.$emit('onChangePagination')
                    } catch (e) {
                        this.$toast.error(e.message)
                    }
                }

            },
            onChangePage(page) {
                this.$emit('onChangePage', page)
            },

        },
    }
</script>

<style lang="less" scoped>
    .pagination-container {
        display: flex;
        justify-content: flex-start;
        margin-left: 16px;
        &-links {
            display: flex;
            min-width: 201px;
            margin-right: 24px;
            &-text {
                align-self: center;
            }
            button {
                padding-top: 3px;
            }
        }
        &-size-selector {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            &-text {
                align-self: center;
            }
        }
    }
</style>
